import { Directive, ElementRef, Renderer2, Input, SimpleChanges, OnChanges } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[ins-decimallize]'
})
export class DecimallizeDirective implements OnChanges {
    @Input('ins-decimallize') decimallize: number;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2
    ) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.decimallize) {
            if (changes.decimallize.currentValue) {
                this.printParts(changes.decimallize.currentValue);
            }
        }
    }

    printParts(value: number) {
        const parts = value.toFixed(2).toString().split('.');

        if (parts.length === 2) {
            this.renderer.setProperty(this.el.nativeElement, 'innerHTML', `${parts[0]}<sup>,${parts[1]}</sup>`);
        } else {
            this.renderer.setProperty(this.el.nativeElement, 'innerHTML', value);
        }
    }
}
