import { NgModule } from '@angular/core';
import { DropdownDirective, DropdownMenuDirective, DropdownToggleDirective } from './dropdown.directive';

@NgModule({
    exports: [
        DropdownDirective,
        DropdownMenuDirective,
        DropdownToggleDirective,
    ],
    declarations: [
        DropdownDirective,
        DropdownMenuDirective,
        DropdownToggleDirective,
    ],
})
export class DropdownModule { }
