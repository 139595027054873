import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScreenSizeDirective } from './screen-size.directive';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        ScreenSizeDirective
    ],
    exports: [
        ScreenSizeDirective
    ],
    providers: [],
})
export class ScreenSizeModule {}
