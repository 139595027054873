import { Directive, ElementRef, HostListener, Inject, Input, OnDestroy, OnInit, Optional, inject } from '@angular/core';
// TODO: (re)move this dependency - https://jira.outshared.com/browse/WINS-10578
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AppRoutesEnum } from '@inshared/shared/core';
import { NativeRouterService } from '@inshared/shared/util';
import { StorageService } from '@inshared/shared/util-storage';
import { WucButtonComponent, WucLinkComponent } from '@inshared/website-ui-components';
import { AuthenticationDataService } from 'outshared-lib';
import { Observable, Subscription } from 'rxjs';

const TARGET_URL = 'targetUrl';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[ins-authorize-before-redirect]',
})
export class AuthorizeBeforeRedirectDirective implements OnInit, OnDestroy {
    @Input() url?: string;

    private subscription: Subscription = new Subscription();
    private nativeRouterService = inject(NativeRouterService);
    private baseUrl = this.nativeRouterService.baseUrl;
    readonly authenticated$: Observable<boolean> = this.authenticationDataService.isLoggedIn$();

    @HostListener('click', ['$event']) onClick(event: MouseEvent): void {
        if (!this.linkComponent && !this.buttonComponent) {
            event.preventDefault();
            this.startNavigation(new URL(this.elem.nativeElement.href).pathname);
        }
    }

    // eslint-disable-next-line no-useless-constructor
    constructor(
        private elem: ElementRef,
        private authenticationDataService: AuthenticationDataService,
        @Inject(StorageService) private storage: Storage,
        @Optional() private linkComponent: WucLinkComponent,
        @Optional() private buttonComponent: WucButtonComponent
    ) {}

    public ngOnInit(): void {
        if (this.linkComponent) {
            const url = this.linkComponent.url;
            this.linkComponent.url = undefined;
            this.subscription.add(this.linkComponent.clicked.subscribe((): void => this.startNavigation(url)));
        }
        if (this.buttonComponent) {
            this.buttonComponent.onClick = (): void => this.startNavigation(this.url);
        }
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private startNavigation(url: string): void {
        const href = new URL(url, this.baseUrl).href;
        this.subscription.add(
            this.authenticated$.subscribe((isAuthenticated: boolean): void => {
                if (isAuthenticated) {
                    this.nativeRouterService.navigateByUrl(href);
                } else {
                    this.storage.setItem(TARGET_URL, JSON.stringify(href));
                    this.nativeRouterService.navigate([this.baseUrl, AppRoutesEnum.Login]);
                }
            })
        );
    }
}
