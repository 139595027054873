import { NgModule } from '@angular/core';
import { AnimateInViewModule } from './animate-in-view';
import { AuthorizeBeforeRedirectModule } from './authorize-before-redirect';
import { ClickOutsideDirectiveModule } from './click-outside';
import { CodeblockModule } from './codeblock';
import { DecimallizeModule } from './decimallize';
import { DisabledModule } from './disabled';
import { DropdownModule } from './dropdown/dropdown.module';
import { HideOnBreakpointModule } from './hide-on-breakpoint';
import { ReadMoreModule } from './read-more/read-more.module';
import { ScreenSizeModule } from './screen-size';
import { ShowPasswordModule } from './show-password';

/**
 * @deprecated try importing specific modules for directives
 */
@NgModule({
    imports: [
        AnimateInViewModule,
        AuthorizeBeforeRedirectModule,
        ClickOutsideDirectiveModule,
        CodeblockModule,
        DecimallizeModule,
        DisabledModule,
        DropdownModule,
        HideOnBreakpointModule,
        ReadMoreModule,
        ScreenSizeModule,
        ShowPasswordModule,
    ],
    exports: [
        AnimateInViewModule,
        AuthorizeBeforeRedirectModule,
        ClickOutsideDirectiveModule,
        CodeblockModule,
        DecimallizeModule,
        DisabledModule,
        DropdownModule,
        HideOnBreakpointModule,
        ReadMoreModule,
        ScreenSizeModule,
    ],
})
export class DirectivesModule {}
